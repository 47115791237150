<!--访问权限管理-->

<template>

</template>

<script>
export default {
  name: "accessuthority"
}
</script>

<style scoped>

</style>
